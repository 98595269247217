import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { ExchangeRequestFileService } from './exchange-request-file.service';
import { ExchangeRequestFileRoutingModule } from './exchange-request-file-routing.module';
import { ExchangeRequestFileListComponent } from './exchange-request-file-list/exchange-request-file-list.component';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { FileDeleteModalComponent } from './file-delete-modal/file-delete-modal.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ExchangeRequestFileRoutingModule,
  ],
  exports: [
    ExchangeRequestFileListComponent,
    FileUploadModalComponent,
    FileDeleteModalComponent,
  ],
  declarations: [
    ExchangeRequestFileListComponent,
    FileUploadModalComponent,
    FileDeleteModalComponent,
  ],
  providers: [
    ExchangeRequestFileService,
  ],
})
export class ExchangeRequestFileModule { }

import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { BaseCrudFormComponent } from '../../base/base-crud-form.component';

import { ExchangeRequestStatus } from '../exchange-request-status';
import { ExchangeRequestStatusService } from '../exchange-request-status.service';


@Component({
  selector: 'app-exchange-request-status-form',
  templateUrl: './exchange-request-status-form.component.html',
  styleUrls: ['./exchange-request-status-form.component.css']
})
export class ExchangeRequestStatusFormComponent extends BaseCrudFormComponent<ExchangeRequestStatus> {

  @Input() record: ExchangeRequestStatus;
  @Input() serviceCall: string;

  @Input() successNotification: string;
  @Input() failedNotification: string;
  @Input() submitText: string;

  recordUrlPrefix = '/exchange/request/status/detail/';

  formErrors = {
    'name': '',
    'detail': '',
    'non_field_errors': '',
  };

  validationMessages = {
    'name': {
      'required':      'Name is required.',
    },
  };

  constructor(protected fb: FormBuilder,
              protected exchangeRequestStatusService: ExchangeRequestStatusService) {
    super(exchangeRequestStatusService);
  }

  buildFormGroup(): FormGroup {
    return this.fb.group({
      'name': [this.record.name, [
          Validators.required,
        ],
      ],
    });
  }

  initFormModel() {
    this.record = new ExchangeRequestStatus('');
  }

  getFormModel() {
    const { name } = this.recordForm.value;
    const id = this.record.id;
    const submitExchangeRequestStatus = new ExchangeRequestStatus(id, name);
    return submitExchangeRequestStatus;
  }
}

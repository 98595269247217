import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { User } from '../user';
import { UserService } from '../user.service';



@Component({
  selector: 'app-user-deactivate-dialog',
  template: `
            <button *ngIf="record.is_active" type="button" mat-raised-button
                    (click)="openDialog()">
              Deactivate
            </button>

            <button *ngIf="!record.is_active" type="button" mat-raised-button
                    color="warn" (click)="openDialog()">
              Activate
            </button>
             `,
})
export class UserDeactivateDialogComponent {

  @Input() record: User;
  @Input() iconOnly = false;

  @Output() onActivateSuccessEmitter = new EventEmitter<User>();
  @Output() onDeactivateSuccessEmitter = new EventEmitter<User>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(UserDeactivateDialogContentComponent, {
      data: { record: this.record }
    });

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const subActivate = ref.componentInstance.onActivateSuccessEmitter.subscribe(
      (record) => this.onActivateSuccess(record)
    );
    const subDeactivate = ref.componentInstance.onDeactivateSuccessEmitter.subscribe(
      (record) => this.onDeactivateSuccess(record)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      subActivate.unsubscribe();
      subDeactivate.unsubscribe();
    });
  }

  public onActivateSuccess(record: User): void {
    this.onActivateSuccessEmitter.emit(record);
  }

  public onDeactivateSuccess(record: User): void {
    this.onDeactivateSuccessEmitter.emit(record);
  }
}


@Component({
  selector: 'app-user-deactivate-dialog-content',
  template: `
            <app-dialog-header>
              {{ data.record.is_active ? 'Dea' : 'A' }}ctivate this user?
            </app-dialog-header>

            <alert type="danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </alert>

            <mat-dialog-content>
              <p>
                Are you sure you want to
                {{ data.record.is_active ? 'de' : '' }}activate this user?
              </p>
              <p>
                Inactive users are not able to sign into the system.
              </p>

              <app-user-card [record]="data.record">
              </app-user-card>

              <div class="button-row">

                <button *ngIf="!data.record.is_active"
                    mat-raised-button type="button"
                    color="warn"
                    (click)="onActivateClicked()"
                    >
                  Activate
                </button>

                <button *ngIf="data.record.is_active"
                    mat-raised-button type="button"
                    color="warn"
                    (click)="onDeactivateClicked()"
                    >
                  Deactivate
                </button>

                <button mat-raised-button type="button"
                    (click)="onCloseClicked()"
                    >
                  Cancel
                </button>
              </div>

            </mat-dialog-content>
            `,
})
export class UserDeactivateDialogContentComponent {

  @Output() onActivateSuccessEmitter = new EventEmitter<User>();
  @Output() onDeactivateSuccessEmitter = new EventEmitter<User>();

  public errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<UserDeactivateDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dataService: UserService
  ) { }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onActivateClicked(): void {
    const is_active = true;
    this.dataService.updateActive(this.data.record.id, is_active)
      .then(() => this.onActivateSuccess(this.data.record))
      .catch(err => this.handleError(err));
  }

  public onDeactivateClicked(): void {
    const is_active = false;
    this.dataService.updateActive(this.data.record.id, is_active)
      .then(() => this.onDeactivateSuccess(this.data.record))
      .catch(err => this.handleError(err));
  }

  public onActivateSuccess(record: User): void {
    this.errorMessage = '';
    this.onActivateSuccessEmitter.emit(record);
    this.dialogRef.close();
  }

  public onDeactivateSuccess(record: User): void {
    this.errorMessage = '';
    this.onDeactivateSuccessEmitter.emit(record);
    this.dialogRef.close();
  }

  protected handleError(error: any): void {
    // @TODO: Improve logging.
    console.log('UserDeactivateDialogContentComponent error: ', error);
    if (error.hasOwnProperty('error')) {
      const errorBody = error.error;
      if (errorBody.hasOwnProperty('detail')) {
        this.errorMessage = errorBody.detail;
      }
    }
  }
}


export class ExchangeRequestFile {

  constructor(
    public id = '',
    public request = '',
    public media_file = '',
    public size = '',
    public created_date = '',
    public modified_date = '',
  ) { }

}

import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,
} from '@angular/router';

import { SessionService } from '../session.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private sessionService: SessionService) { }

  /** If user is anonymous, disallow, and redirect to front page.
   *  This protects private UI views from the public web.
   **/
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.sessionService.enforceSession() ? true : false;
  }
}

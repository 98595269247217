import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeRedirectGuardService } from './route-guard/home-redirect-guard.service';
import { AuthGuardService } from './route-guard/auth-guard.service';

import { PasswordResetConfirmComponent } from './password-reset-confirm/password-reset-confirm.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { RegisterConfirmComponent } from './register-confirm/register-confirm.component';
import { LogoutComponent } from './logout.component';


const routes: Routes = [
  {
    path: 'password/reset/:uid/:token',
    component: PasswordResetConfirmComponent,
    canActivate: [HomeRedirectGuardService],
  },
  {
    path: 'password/reset',
    component: PasswordResetComponent,
    canActivate: [HomeRedirectGuardService],
  },
  {
    path: 'password/change',
    component: PasswordChangeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'register/:uid/:token',
    component: RegisterConfirmComponent,
    canActivate: [HomeRedirectGuardService],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}

import {Component, Input} from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { FileFormat } from '../file-format';
import { FileFormatService } from '../file-format.service';
import { initFileFormatFormGroup } from '../file-format-fields/file-format-fields.component';


/**
 * FileFormat form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-file-format-form',
  templateUrl: './file-format-form.component.html',
  styleUrls: ['./file-format-form.component.css']
})
export class FileFormatFormComponent extends BaseFormComponent<FileFormat> {
  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input
  constructor(
    protected dataService: FileFormatService,
  ) {
    super(FileFormat, initFileFormatFormGroup, dataService);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      name = '', extension = ''
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${name}`,
      `Extension: ${extension}`
    ].join('\n');
  }
}

import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CampusSystem } from '../campus-system';


@Component({
  selector: 'app-campus-system-dialog',
  template: `
            <button *ngIf="!iconOnly" type="button" mat-raised-button
                    [color]="buttonColor" (click)="openDialog()">
              <span *ngIf="!recordId">
                  Add <mat-icon>add_box</mat-icon>
              </span>
              <span *ngIf="recordId">
                  Edit <mat-icon>edit</mat-icon>
              </span>
            </button>

            <button *ngIf="iconOnly && !recordId"
                    type="button" mat-mini-fab
                    [color]="buttonColor" (click)="openDialog()"
                    aria-label="New campus system"
                    >
              <span> <mat-icon>add</mat-icon> </span>
            </button>
            <button *ngIf="iconOnly && recordId"
                    type="button" mat-mini-fab
                    [color]="buttonColor" (click)="openDialog()"
                    aria-label="Edit campus system"
                    >
              <span> <mat-icon>edit</mat-icon> </span>
            </button>
             `,
})
export class CampusSystemDialogComponent {

  @Input() recordId: string;
  @Input() iconOnly = false;
  @Input() buttonColor = '';
  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input

  @Output() onSubmitSuccessEmitter = new EventEmitter<CampusSystem>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(CampusSystemDialogContentComponent, {
      data: { recordId: this.recordId }
    });

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const sub = ref.componentInstance.onSubmitSuccessEmitter.subscribe(
      (record) => this.onSubmitSuccess(record)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  public onSubmitSuccess(record: CampusSystem): void {
    this.onSubmitSuccessEmitter.emit(record);
  }
}


@Component({
  selector: 'app-campus-system-dialog-content',
  template: `
            <app-dialog-header>
              {{ (data.recordId ? 'Edit' : 'Create') }} Campus System
            </app-dialog-header>

            <mat-dialog-content>
              <app-campus-system-form
                 [injectedRecordId]="data.recordId"
                 (onCloseClickedEmitter)="dialogRef.close()"
                 (onSubmitSuccessEmitter)="onSubmitSuccess($event)"
              >
              </app-campus-system-form>
            </mat-dialog-content>
            `,
})
export class CampusSystemDialogContentComponent {
  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input
  @Output() onSubmitSuccessEmitter = new EventEmitter<CampusSystem>();

  constructor(
    public dialogRef: MatDialogRef<CampusSystemDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public onSubmitSuccess(record: CampusSystem): void {
    this.onSubmitSuccessEmitter.emit(record);
    this.onCloseClicked();
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}

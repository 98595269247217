import {Component, Input} from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { Publisher } from '../publisher';
import { PublisherService } from '../publisher.service';
import { initPublisherFormGroup } from '../publisher-fields/publisher-fields.component';


/**
 * Publisher form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-publisher-form',
  templateUrl: './publisher-form.component.html',
  styleUrls: ['./publisher-form.component.css']
})
export class PublisherFormComponent extends BaseFormComponent<Publisher> {
  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input
  constructor(
    protected dataService: PublisherService,
  ) {
    super(Publisher, initPublisherFormGroup, dataService);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const {
      name = '', url = '', email = ''
    } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${name}`,
      `URL: ${url}`,
      `E-Mail: ${email}`,
    ].join('\n');
  }
}

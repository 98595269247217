import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { AcademicTermListComponent } from './academic-term-list/academic-term-list.component';
import { AcademicTermDetailComponent } from './academic-term-detail/academic-term-detail.component';


const routes: Routes = [
  {
    path: 'academic-term/detail/:id',
    component: AcademicTermDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'academic-term',
    component: AcademicTermListComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AcademicTermRoutingModule {}

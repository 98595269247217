import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CampusModule } from '../campus/campus.module';

import { UserService } from './user.service';
import { UserProfileService } from './user-profile.service';
import { UserPreferenceService } from './user-preference.service';
import { UserRoutingModule } from './user-routing.module';

import { UserComponent } from './user.component';
import { UserCardComponent } from './user-card.component';
import { UserPreferenceFormComponent } from './user-preference-form/user-preference-form.component';
import { UserPreferenceFieldsComponent } from './user-preference-fields/user-preference-fields.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import {
  UserDialogComponent, UserDialogContentComponent
} from './user-dialog/user-dialog.component';
import {
  UserDeactivateDialogComponent, UserDeactivateDialogContentComponent
} from './user-deactivate-dialog/user-deactivate-dialog.component';
import { UserFieldsComponent } from './user-fields/user-fields.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserProfileFormComponent } from './user-profile-form/user-profile-form.component';
import { UserProfileComponent } from './user-profile/user-profile.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CampusModule,
    UserRoutingModule,
  ],
  exports: [
    UserComponent,
    UserCardComponent,
    UserListComponent,
    UserDetailComponent,
  ],
  declarations: [
    UserComponent,
    UserCardComponent,
    UserPreferenceFormComponent,
    UserPreferenceFieldsComponent,
    UserListComponent,
    UserDetailComponent,
    UserDialogComponent,
    UserDialogContentComponent,
    UserDeactivateDialogComponent,
    UserDeactivateDialogContentComponent,
    UserFieldsComponent,
    UserFormComponent,
    UserProfileFormComponent,
    UserProfileComponent,
  ],
  entryComponents: [
    UserDialogContentComponent,
    UserDeactivateDialogContentComponent,
  ],
  providers: [
    UserService,
    UserProfileService,
    UserPreferenceService,
  ],
})
export class UserModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { PublisherDetailComponent } from './publisher-detail/publisher-detail.component';
import { PublisherListComponent } from './publisher-list/publisher-list.component';


const routes: Routes = [
  {
    path: 'publisher/detail/:id',
    component: PublisherDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'publisher/create',
    component: PublisherDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'publisher',
    component: PublisherListComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublisherRoutingModule {}

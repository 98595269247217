import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { ExchangeRequestStatusListComponent } from './exchange-request-status-list/exchange-request-status-list.component';
import { ExchangeRequestStatusCreateComponent } from './exchange-request-status-create/exchange-request-status-create.component';


const routes: Routes = [
  {
    path: 'exchange/request/status/create',
    component: ExchangeRequestStatusCreateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'exchange/request/status',
    component: ExchangeRequestStatusListComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExchangeRequestStatusRoutingModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { HoldingBookSourceListComponent } from './holding-book-source-list/holding-book-source-list.component';


const routes: Routes = [
  {
    path: 'holding/book/source',
    component: HoldingBookSourceListComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HoldingBookSourceRoutingModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { HoldingBookListComponent } from './holding-book-list/holding-book-list.component';


const routes: Routes = [
  {
    path: 'holding/book',
    component: HoldingBookListComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HoldingBookRoutingModule {}


export class ExchangeRequest {

  constructor(
    public id = '',
    public holding = '',
    public holding_str = '',
    public requesting_user = '',
    public requesting_user_str = '',
    public requesting_campus = '',
    public requesting_campus_str = '',
    public holding_campus = '',
    public holding_campus_str = '',
    public holding_title = '',
    public holding_title_str = '',
    public holding_isbn = '',
    public holding_isbn_str = '',
    public holding_format = '',
    public holding_format_str = '',
    public holding_source = '',
    public holding_source_str = '',
    public term = '',
    public term_str = '',
    public status = '',
    public status_str = '',
    public created_date = '',
    public modified_date = '',
    public close_date = '',
    public elapsed_days = '',
  ) { }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { ReportCampusSystemComponent } from './campus-system/report.component';


const routes: Routes = [
  {
    path: 'reports/campus-system',
    component: ReportCampusSystemComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {}

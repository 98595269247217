import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';

import { CampusDetailComponent } from './campus-detail/campus-detail.component';
import { CampusListComponent } from './campus-list/campus-list.component';
import { CampusProfileComponent } from './campus-profile/campus-profile.component';


const routes: Routes = [
  {
    path: 'campus/create',
    component: CampusDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'campus/detail/:id',
    component: CampusDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'campus/profile',
    component: CampusProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'campus',
    component: CampusListComponent,
    canActivate: [AuthGuardService],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampusRoutingModule {}

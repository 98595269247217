import { Component, Input, Output, Inject, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserService } from '../../user/user.service';
import { User } from '../../user/user';

import { AcademicTermService } from '../../academic-term/academic-term.service';
import { AcademicTerm } from '../../academic-term/academic-term';
import { HoldingBook } from '../../holding-book/holding-book';

import { ExchangeRequest } from '../exchange-request';
import { ExchangeRequestService } from '../exchange-request.service';



@Component({
  selector: 'app-request-confirm-dialog',
  template: `
            <button *ngIf="!iconOnly" type="button" mat-raised-button
                    color="primary" (click)="openDialog()">
              Request <mat-icon>send</mat-icon>
            </button>

            <button *ngIf="iconOnly" type="button" mat-mini-fab
                    color="primary" (click)="openDialog()">
              <mat-icon>send</mat-icon>
            </button>
             `,
})
export class RequestConfirmDialogComponent {

  @Input() record: ExchangeRequest;
  @Input() holding: HoldingBook;

  @Input() iconOnly = false;

  @Output() onRequestSuccessEmitter = new EventEmitter<ExchangeRequest>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(RequestConfirmDialogContentComponent, {
      data: {
        record: this.record,
        holding: this.holding,
      }
    });

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const sub = ref.componentInstance.onRequestSuccessEmitter.subscribe(
      (record) => this.onRequestSuccess(record)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  public onRequestSuccess(record: ExchangeRequest): void {
    this.onRequestSuccessEmitter.emit(record);
  }
}


@Component({
  selector: 'app-request-confirm-dialog-content',
  template: `
          <span class="request-confirm-dialog">
            <app-dialog-header>
              Confirm Your Request
            </app-dialog-header>

            <alert type="danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </alert>

            <mat-dialog-content>

              <p>Please confirm your request for this holding.</p>

              <div class="confirm-column">
                <app-holding-book-card [record]="data.holding">
                </app-holding-book-card>
              </div>

              <div class="confirm-column">
              <form id="request-confirmation">

                <div *ngIf="academicTerms">
                  <mat-form-field
                      class="term-select"
                      >
                    <mat-select
                        placeholder="Academic Term"
                        [(ngModel)]="selectedTermId"
                        name="academic-terms"
                        >
                      <mat-option *ngFor="let term of academicTerms"
                          [value]="term.id"
                          >
                        {{ term.season }} {{ term.year }} {{ term.session }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="!academicTerms">
                  No academic terms available.
                </div>

                <mat-checkbox
                    [(ngModel)]="certified"
                    name="certify-request"
                    class="certify-checkbox"
                    >
                  I certify that this book is being requested for a student with a
                  verified print disability, in accordance with local and federal laws.
                </mat-checkbox>
              </form>
            </div>

                <div class="button-row">
                  <button mat-raised-button type="button"
                      color="primary"
                      [disabled]="!certified"
                      (click)="onRequestClicked()"
                      >
                    Confirm Request <mat-icon>send</mat-icon>
                  </button>
                  <button mat-raised-button type="button"
                      (click)="onCloseClicked()"
                      >
                    Cancel
                  </button>
                </div>

            </mat-dialog-content>
          </span>
            `,
  styles: [`
    .request-confirm-dialog {
      max-width: 200px;
    }
    .confirm-column {
      display: inline-block;
      vertical-align:top;
      padding-top: 20px;
      width: 49%;
      max-width: 250px;
    }
    .term-select {
      float: left;
      margin-left: 25px;
      padding-left: 25px;
      padding-botton: 20px;
    }
  `],
})
export class RequestConfirmDialogContentComponent implements OnInit {

  @Output() onRequestSuccessEmitter = new EventEmitter<ExchangeRequest>();

  public academicTerms: AcademicTerm[] = [];

  public errorMessage: string;
  public certified = false;
  public current_user: User;
  public selectedTermId: string;

  constructor(
    public dialogRef: MatDialogRef<RequestConfirmDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dataService: ExchangeRequestService,
    protected academicTermService: AcademicTermService,
    protected userService: UserService
  ) { }

  public ngOnInit() {
    try {
      this.getUser();
      this.getTermList();
    } catch (e) {
      this.handleError(e);
    }
  }

  async getUser() {
    try {
      const response = await this.userService.getProfile();
      this.current_user = response;
    } catch (e) {
      this.handleError(e);
    }
  }

  async getTermList(): Promise<void> {
    try {
      const response = await this.academicTermService.list();
      this.academicTerms = response['results'] as AcademicTerm[];

      if (this.academicTerms.length > 0) {
        this.selectedTermId = this.academicTerms[0].id;
      }
    } catch (e) {
      console.error(e);
      this.handleError(e);
    }
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onRequestClicked(): void {

    let requestData = {
      'holding': this.data.holding.id,
      'term': this.selectedTermId,
      'requesting_user': this.current_user['id'],
      'requesting_campus': this.current_user['campus'],
    };

    this.dataService
      .request(requestData)
      .then(newRequest => this.onRequestSuccess(newRequest))
      .catch(err => this.handleError(err));
  }

  public onRequestSuccess(record: ExchangeRequest): void {
    this.errorMessage = '';
    this.certified = false;

    this.onRequestSuccessEmitter.emit(record);
    this.dialogRef.close();
  }

  protected handleError(error: any): void {
    // @TODO: Improve logging.
    console.error('RequestConfirmDialogContentComponent error: ', error);
    const defaultMessage = 'Could not submit request at this time.';
    this.errorMessage = error.error ? error.error[0] : defaultMessage;
  }
}

import {Component, Input} from '@angular/core';

import { BaseFormComponent } from '../../base/base-form.component';

import { HoldingBookSource } from '../holding-book-source';
import { HoldingBookSourceService } from '../holding-book-source.service';
import { initHoldingBookSourceFormGroup } from '../holding-book-source-fields/holding-book-source-fields.component';


/**
 * HoldingBookSource form for create/update.
 * Makes the service calls to the back end API.
 */
@Component({
  selector: 'app-holding-book-source-form',
  templateUrl: './holding-book-source-form.component.html',
  styleUrls: ['./holding-book-source-form.component.css']
})
export class HoldingBookSourceFormComponent extends BaseFormComponent<HoldingBookSource> {
  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input
  constructor(
    protected dataService: HoldingBookSourceService,
  ) {
    super(HoldingBookSource, initHoldingBookSourceFormGroup, dataService);
  }

  /* Update text that would be copied to clipboard.
   */
  protected _updateClipboardText(): void {
    // Get target form values.
    const { name = '' } = this.formComposite.value.record;

    // Format clipboard contents.
    this.clipboardText = [
      `Name: ${name}`,
    ].join('\n');
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../auth/route-guard/auth-guard.service';
import { HomeRedirectGuardService } from '../auth/route-guard/home-redirect-guard.service';

import { FrontPageComponent } from './front-page/front-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorNotFoundComponent } from './error-not-found/error-not-found.component';


const routes: Routes = [
  {
    path: '',
    component: FrontPageComponent,
    pathMatch: 'full',
    canActivate: [HomeRedirectGuardService],
  },
  {
    path: 'home',
    component: HomePageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'not-found',
    component: ErrorNotFoundComponent,
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {}

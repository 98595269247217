import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { BookModule } from '../book/book.module';
import { HoldingBookModule } from '../holding-book/holding-book.module';

import { ExchangeRequestLogModule } from '../exchange-request-log/exchange-request-log.module';
import { ExchangeRequestFileModule } from '../exchange-request-file/exchange-request-file.module';

import { ExchangeRequestService } from './exchange-request.service';
import { ExchangeRequestRoutingModule } from './exchange-request-routing.module';


import { ExchangeRequestCardComponent } from './exchange-request-card.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import {
  RequestConfirmDialogComponent,
  RequestConfirmDialogContentComponent,
} from './request-confirm-dialog/request-confirm-dialog.component';
import { ExchangeRequestBaseListComponent } from './exchange-request-base-list/exchange-request-base-list.component';
import { ExchangeRequestInListComponent } from './exchange-request-in-list/exchange-request-in-list.component';
import { ExchangeRequestOutListComponent } from './exchange-request-out-list/exchange-request-out-list.component';
import { ExchangeRequestDetailComponent } from './exchange-request-detail/exchange-request-detail.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BookModule,
    HoldingBookModule,
    ExchangeRequestLogModule,
    ExchangeRequestFileModule,
    ExchangeRequestRoutingModule,
  ],
  exports: [
    ExchangeRequestCardComponent,
    ConfirmModalComponent,
    RequestConfirmDialogComponent,
    RequestConfirmDialogContentComponent,
    ExchangeRequestBaseListComponent,
    ExchangeRequestInListComponent,
    ExchangeRequestOutListComponent,
    ExchangeRequestDetailComponent,
  ],
  declarations: [
    ExchangeRequestCardComponent,
    ConfirmModalComponent,
    RequestConfirmDialogComponent,
    RequestConfirmDialogContentComponent,
    ExchangeRequestBaseListComponent,
    ExchangeRequestInListComponent,
    ExchangeRequestOutListComponent,
    ExchangeRequestDetailComponent,
  ],
  entryComponents: [
    RequestConfirmDialogContentComponent,
  ],
  providers: [
    ExchangeRequestService,
  ],
})
export class ExchangeRequestModule { }

import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HoldingBookService } from '../holding-book.service';



@Component({
  selector: 'app-holding-book-all-public-dialog',
  template: `
            <button *ngIf="!iconOnly" type="button" mat-raised-button
                    color="warn" (click)="openDialog()">
              Make All Public <mat-icon>public</mat-icon>
            </button>

            <button *ngIf="iconOnly" type="button" mat-mini-fab
                    color="warn" (click)="openDialog()">
              <mat-icon>public</mat-icon>
            </button>
             `,
})
export class HoldingBookAllPublicDialogComponent {

  @Input() iconOnly = false;

  @Output() onMakeAllPublicSuccessEmitter = new EventEmitter<number>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(
      HoldingBookAllPublicDialogContentComponent,
      { data: {} }
    );

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const sub = ref.componentInstance.onMakeAllPublicSuccessEmitter.subscribe(
      (updateCount) => this.onMakeAllPublicSuccess(updateCount)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  public onMakeAllPublicSuccess(updateCount: number): void {
    this.onMakeAllPublicSuccessEmitter.emit(updateCount);
  }
}


@Component({
  selector: 'app-holding-book-all-public-dialog-content',
  template: `
            <app-dialog-header>
              Make All Public
            </app-dialog-header>

            <alert type="danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </alert>

            <mat-dialog-content>
              <p>Are you sure you want to make all holdings public?</p>

              <p>
                Public holdings appear in search results, and can be
                requested by other campuses. Public holdings are
                what make AIMHub's exchange work.
              </p>

              <p> Once holdings become public, they cannot be private again. </p>

              <div class="button-row">
                <button mat-raised-button type="button"
                    color="warn"
                    (click)="onMakeAllPublicClicked()"
                    >
                  Make All Public <mat-icon>public</mat-icon>
                </button>
                <button mat-raised-button type="button"
                    (click)="onCloseClicked()"
                    >
                  Cancel
                </button>
              </div>

            </mat-dialog-content>
            `,
})
export class HoldingBookAllPublicDialogContentComponent {

  @Output() onMakeAllPublicSuccessEmitter = new EventEmitter<number>();

  public errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<HoldingBookAllPublicDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dataService: HoldingBookService
  ) { }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public onMakeAllPublicClicked(): void {
    this.dataService.makeAllPublic()
      .then(response => this.onMakeAllPublicSuccess(response))
      .catch(err => this.handleError(err));
  }

  public onMakeAllPublicSuccess(response: any): void {
    this.errorMessage = '';
    this.onMakeAllPublicSuccessEmitter.emit(response['update_count']);
    this.dialogRef.close();
  }

  protected handleError(error: any): void {
    // @TODO: Improve logging.
    console.log('HoldingBookAllPublicDialogContentComponent error: ', error);
    const errorBody = error;
    if (errorBody.hasOwnProperty('detail')) {
      this.errorMessage = errorBody.detail;
    }
  }
}

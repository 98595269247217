import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Book } from '../book';


@Component({
  selector: 'app-book-dialog',
  template: `
            <button *ngIf="!iconOnly && !customButtonText"
                    type="button" mat-raised-button
                    [color]="buttonColor" (click)="openDialog()">
              <span *ngIf="!recordId">
                  Add <mat-icon>add_box</mat-icon>
              </span>
              <span *ngIf="recordId">
                  Edit <mat-icon>edit</mat-icon>
              </span>
            </button>

            <div *ngIf="iconOnly && !customButtonText">
              <button *ngIf="!recordId"
                      type="button" mat-mini-fab
                      [color]="buttonColor" (click)="openDialog()"
                      aria-label="New book"
                      >
                <span> <mat-icon>add</mat-icon> </span>
              </button>

              <button *ngIf="!recordId"
                      type="button" mat-mini-fab
                      [color]="buttonColor" (click)="openDialog()"
                      aria-label="Edit book"
                      >
                <span> <mat-icon>edit</mat-icon> </span>
              </button>
            </div>

            <button *ngIf="customButtonText"
                    type="button" mat-raised-button
                    [color]="buttonColor" (click)="openDialog()">
              <ng-content>
              </ng-content>
            </button>
             `,
})
export class BookDialogComponent {

  @Input() recordId: string;
  @Input() iconOnly = false;
  @Input() buttonColor = '';
  @Input() customButtonText = false;

  @Output() onSubmitSuccessEmitter = new EventEmitter<Book>();

  constructor(
    public dialog: MatDialog
  ) { }

  protected openDialog(): void {
    // Open the dialog with record ID.
    const ref = this.dialog.open(BookDialogContentComponent, {
      data: { recordId: this.recordId }
    });

    // Subscribe to the child emitter in code.
    // On success, forward the emitted record up the chain.
    const sub = ref.componentInstance.onSubmitSuccessEmitter.subscribe(
      (record) => this.onSubmitSuccess(record)
    );

    // Unsubscribe after close.
    ref.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  public onSubmitSuccess(record: Book): void {
    this.onSubmitSuccessEmitter.emit(record);
  }
}


@Component({
  selector: 'app-book-dialog-content',
  template: `
            <app-dialog-header>
              {{ (data.recordId ? 'Edit' : 'Create') }} Book
            </app-dialog-header>

            <mat-dialog-content>
              <app-book-form
                 [injectedRecordId]="data.recordId"
                 (onCloseClickedEmitter)="dialogRef.close()"
                 (onSubmitSuccessEmitter)="onSubmitSuccess($event)"
              >
              </app-book-form>
            </mat-dialog-content>
            `,
})
export class BookDialogContentComponent {
  @Input() injectedRecordId: string;  // Explicitly redeclare this as an input
  @Output() onSubmitSuccessEmitter = new EventEmitter<Book>();

  constructor(
    public dialogRef: MatDialogRef<BookDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public onSubmitSuccess(record: Book): void {
    this.onSubmitSuccessEmitter.emit(record);
    this.onCloseClicked();
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

}
